// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require cocoon

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// jquery は expose-loader で global に配置される
// https://www.npmjs.com/package/expose-loader/v/1.0.3
// application.js は全てのページで呼ばれるので、以下の import を他の js で指定する必要はない
import $ from "expose-loader?exposes[]=$&exposes[]=jQuery!jquery"
import 'bootstrap'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.ja'
import 'bootstrap-timepicker/js/bootstrap-timepicker'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'slick-carousel'
import 'jquery-jpostal-ja'

require("chart.js/dist/chart.min.js")
require("@nathanvda/cocoon")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function () {
  $('[data-toggle="tooltip"]').tooltip();

  // ブラウザの戻るボタンで戻ってきた場合は強制的にリロードする
  history.replaceState(null, document.getElementsByTagName('title')[0].innerHTML, null);
  window.addEventListener('popstate', function (e) {
    window.location.reload();
  });

  // datepickerの開始日の初期値がUTCになってしまうため、JSTに変更する処理 issue#2715
  (function() {
    // datepickerがないページでは処理を終了
    if ($('input[data-provide="datepicker"]').length === 0) {
      return;
    }

    // 今日の日付をJSTで取得
    const today = new Date();

    $('input[data-provide="datepicker"]').each(function() {
      var $this = $(this);
      // 開始日の初期値(UTC)
      var startDateAttr = $this.data('date-start-date');

      // startDateAttrが無い場合は次の要素へ
      if (!startDateAttr) {
        return true;  // これにより次のループへ
      }

      let startDate = new Date(today);
      if (startDateAttr.match(/^\+\d+d$/)) {
        const days = parseInt(startDateAttr.replace('+', '').replace('d', ''));
        startDate.setDate(today.getDate() + days);
      } else if (startDateAttr.match(/^\+\d+w$/)) {
        const weeks = parseInt(startDateAttr.replace('+', '').replace('w', ''));
        startDate.setDate(today.getDate() + (weeks * 7));
      } else if (startDateAttr.match(/^\+\d+m$/)) {
        const months = parseInt(startDateAttr.replace('+', '').replace('m', ''));
        startDate.setMonth(today.getMonth() + months);
      } else {
        startDate = new Date(startDateAttr);
      }

      const day = startDate.getDate();
      const month = startDate.getMonth() + 1;
      const year = startDate.getFullYear();
      const formattedDate = year + '/' + (month < 10 ? '0' : '') + month + '/' + (day < 10 ? '0' : '') + day;

      // datepickerに開始日を設定
      $this.datepicker({
        startDate: formattedDate
      });
    });
  })();

  $('#company-applications-form-start_date').datepicker().on('changeDate', function (e) {
    let start_date = new Date(e.date);
    start_date.setMonth(e.date.getMonth() + 3);
    start_date.setDate(start_date.getDate() + 14);

    $('#company-applications-form-negotiation_date').datepicker('setStartDate', start_date);
    $('#company-applications-form-negotiation_date').datepicker('update');

    if ( !!$(this).val() ) {
      $(this).removeClass('is-invalid');
    }
  });

  $('#company-applications-form-negotiation_date').datepicker().on('changeDate', function (e) {
    if ( !!$(this).val() ) {
      $(this).removeClass('is-invalid');
    }
  });
});
